import { Box, Header, Pagination, Table, Toggle, Modal, Button } from "@awsui/components-react";
import { FC, useEffect, useState } from "react";
import { PhoneConfigModel } from "../../models/PhoneConfigModel";

const PAGE_SIZE = 10;

interface PhoneConfigListProps {
    setSelectedConfig: (config: PhoneConfigModel | undefined) => void;
    selectedConfigId: string | undefined;
    originalConfigs: PhoneConfigModel[];
    selectedSurveyId: string | undefined;
    isEditable?: boolean | undefined;
    updatedConfigs: PhoneConfigModel[];
    setUpdatedConfigs: (configs: PhoneConfigModel[]) => void;
}

const PhoneConfigList: FC<PhoneConfigListProps> = (props) => {
    const [configs, setConfigs] = useState<PhoneConfigModel[]>([]);
    const [displayedConfigs, setDisplayedConfigs] = useState<PhoneConfigModel[]>([]);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [displayWarningModal, setDisplayWarningModal] = useState(false);
    const [warningModalProps, setWarningModalProps] = useState<{config: PhoneConfigModel, surveyId: string | undefined} | undefined>(undefined);

    useEffect(() => {
        setConfigs(Array.from(props.originalConfigs));
    }, [props.selectedSurveyId]);

    useEffect(() => {
        setDisplayedConfigs(configs.slice(0, PAGE_SIZE));
    }, [configs]);

    useEffect(() => {
        setConfigs(Array.from(props.originalConfigs));
    }, [props.isEditable]);

    const getCurrentPageSlice = (index: number) => {
        return configs.slice((index - 1) * PAGE_SIZE, (index - 1) * PAGE_SIZE + PAGE_SIZE);
    }

    const paginate = (index: number) => {
        props.setSelectedConfig(undefined);
        setDisplayedConfigs(getCurrentPageSlice(index))
        setCurrentPageIndex(index);
    };

    const hideWarningModal = () => {
        setWarningModalProps(undefined);
        setDisplayWarningModal(false);
    }

    const promptSetSurveyId = (config: PhoneConfigModel, surveyId: string | undefined) => {
        if (!!config.surveyId && config.surveyId !== 'NEW_SURVEY' && config.surveyId !== props.selectedSurveyId) {
            setWarningModalProps({config, surveyId});
            setDisplayWarningModal(true);
        } else {
            setSurveyId(config, surveyId);
        }
    }

    const setSurveyId = (config: PhoneConfigModel | undefined, surveyId: string | undefined) => {
        hideWarningModal();

        if (!config) {
            return;
        }

        let configIndex = -1;
        for (let i = 0; i < configs.length; i++) {
            if (configs[i].Client === config.Client && configs[i].systemId === config.systemId) {
                configIndex = i;
                break;
            }
        }
        if (configIndex < 0) {
            return;
        }
        const newConfig = {
            ...configs[configIndex],
            surveyId: surveyId
        }

        configs[configIndex] = newConfig
        setDisplayedConfigs(getCurrentPageSlice(currentPageIndex));

        if (props.originalConfigs[configIndex].surveyId === surveyId) {
            for (let i = 0; i < props.updatedConfigs.length; i++) {
                const updatedConfig = props.updatedConfigs[i];
                if (updatedConfig.systemId === config.systemId && updatedConfig.Client === config.Client) {
                    props.updatedConfigs.splice(i, 1);
                    break;
                }
            }
        } else {
            props.updatedConfigs.push(newConfig);
        }
    }

    return (
        <>
            <Modal visible={displayWarningModal} onDismiss={(event) => hideWarningModal()} 
                header='This client already has a survey attached. If you continue, the existing survey will be replaced with this survey'>
                <span className='flex-align-right'>
                    <Button onClick={(event) => hideWarningModal()}>Cancel</Button>
                    <Button onClick={(event) => setSurveyId(warningModalProps?.config, warningModalProps?.surveyId)}>Continue</Button>
                </span>
            </Modal>
            <Table
                columnDefinitions={[
                    {
                        id: "Client",
                        header: "Client",
                        cell: (e) => e.Client,
                    },
                    {
                        id: "systemId",
                        header: "System ID",
                        cell: (e) => e.systemId,
                    },
                    {
                        id: "useSurvey",
                        header: "Use Survey?",
                        cell: (e) => <Toggle disabled={!props.isEditable} checked={!!e.surveyId && ((!props.selectedSurveyId && e.surveyId === 'NEW_SURVEY') || e.surveyId === props.selectedSurveyId)} 
                            onChange={(event) => promptSetSurveyId(e, !props.selectedSurveyId ? (e.surveyId === 'NEW_SURVEY' ? undefined : 'NEW_SURVEY')
                             : e.surveyId === props.selectedSurveyId ? undefined : props.selectedSurveyId)}/>
                    },
                ]}
                items={displayedConfigs}
                loadingText="Loading configurations"
                trackBy="systemId"
                visibleColumns={["Client", "systemId", "useSurvey"]}
                empty={
                    <Box textAlign="center" color="inherit">
                        <b>No Client Configurations</b>
                        <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                            No client configurations to display.
                        </Box>
                    </Box>
                }
                header={
                    <Header>
                        Client Configurations{" "}
                    </Header>
                }
                pagination={
                    <Pagination
                        currentPageIndex={currentPageIndex}
                        pagesCount={Math.ceil(configs.length / PAGE_SIZE)}
                        onChange={(event) => {
                            paginate(event.detail.currentPageIndex);
                        }}
                        ariaLabels={{
                            nextPageLabel: "Next page",
                            previousPageLabel: "Previous page",
                            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                        }}
                    />
                }
            />
        </>
    );
};

export default PhoneConfigList;
