import { Box, Button, Header, Pagination, Table, SpaceBetween } from "@awsui/components-react";
import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { SurveyModel } from "../../models/SurveyModel";
import { Auth } from "@aws-amplify/auth";
import { PhoneConfigModel } from "../../models/PhoneConfigModel";
import Survey from "../Survey/Survey";

const SurveysList: FC = (props) => {
    const [selectedSurvey, setSelectedSurvey] = useState<SurveyModel>();
    const [selectedItems, setSelectedItems] = useState<SurveyModel[]>([]);
    const [surveys, setSurveys] = useState<SurveyModel[]>([]);
    const [displayedSurveys, setDisplayedSurveys] = useState<SurveyModel[]>([]);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [configs, setConfigs] = useState<PhoneConfigModel[]>([]);
    const [updatedConfigs, setUpdatedConfigs] = useState<PhoneConfigModel[]>([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [loading, setLoading] = useState(false);

    var appConfiguration: any = (window as any).app_configuration;

    useEffect(() => {
        if(selectedSurvey?.surveyId !== "") {
            setIsEditMode(false);
        } else {
            setIsEditMode(true);
        }  
    }, [selectedSurvey]);

    useEffect(() => {
        if (!isEditMode && selectedSurvey?.surveyId === "") {
            setSelectedSurvey(undefined);
        }
    }, [isEditMode])

    useEffect(() => {
        setLoading(true);
        fetchSurveys().then((data) => {
            setSurveys(data.surveysList);
            setConfigs(data.configsList);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setDisplayedSurveys(surveys.slice(0, 5));
    }, [surveys]);

    useEffect(() => {
        if (selectedItems.length === 1) {
            setSelectedSurvey(selectedItems[0]);
        }
    }, [selectedItems]);

    useEffect(() => {
        if (selectedSurvey?.surveyId !== selectedItems[0]?.surveyId) {
            if (selectedSurvey?.surveyId === "") {
                setSelectedItems([]);
            } else {
                setLoading(true);
                setUpdatedConfigs([]);
                fetchSurveys().then((data) => {
                    setSurveys(data.surveysList);
                    let selectedItem: SurveyModel[] = [];

                    if (data.surveysList.find(o => o.surveyId === selectedSurvey?.surveyId)) {
                        let selectSurvey: SurveyModel = data.surveysList.find(o => o.surveyId === selectedSurvey?.surveyId)!;
                        selectedItem.push(selectSurvey);
                    }

                    setSelectedItems(selectedItem);
                    setLoading(false);
                });
            }
        }
    }, [selectedSurvey]);

    const paginate = (index: number) => {
        setSelectedItems([]);
        setSelectedSurvey(undefined);
        setDisplayedSurveys(surveys.slice((index - 1) * 5, (index - 1) * 5 + 5));
        setCurrentPageIndex(index);
    };

    const refresh = (event: any, selectedSurvey: SurveyModel | undefined = undefined) => {
        setSelectedItems([]);
        setSelectedSurvey(undefined);
        setLoading(true);
        setIsEditMode(false);
        setUpdatedConfigs([]);
        fetchSurveys().then((data) => {
            setSurveys(data.surveysList);
            setConfigs(data.configsList);
            setLoading(false);
        });
    }

    const createSurvey = () => {
        let newSurvey: SurveyModel = {
            surveyId: "",
            surveyName: "",
            min: 0,
            max: 9,
            introPrompt: "",
            outroPrompt: "",
            questions: [],
            flags: []
        }

        setSelectedSurvey(newSurvey);
    };

    const fetchSurveys = async () => {
        const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();

        const res = await axios.get(`${appConfiguration.api_endpoint}listSurveys`, { headers: { "Authorization": jwt } });
        const surveysList: SurveyModel[] = res.data.surveyData.map((o: any) => {
            let questions: string[] = [];
            let questionsKeys = Object.keys(o).filter((k) => k.startsWith("question_"));
            questionsKeys.sort();
            questionsKeys.forEach((key) => {
                questions.push(o[key]);
            });

            let flags: string[] = [];
            let flagsKeys = Object.keys(o).filter((k) => k.startsWith("flag_"));
            flagsKeys.sort();
            flagsKeys.forEach((key) => {
                flags.push(o[key]);
            });

            return {
                surveyId: o.surveyId,
                surveyName: o.surveyName,
                min: parseInt(o.min),
                max: parseInt(o.max),
                introPrompt: o.introPrompt,
                outroPrompt: o.outroPrompt,
                questions: questions,
                flags: flags,
            };
        });

        const configsList: PhoneConfigModel[] = res.data.configsData;

        return { surveysList, configsList };
    };

    return (
        <>
            <SpaceBetween direction="horizontal" size="xs" className="flex-align-right">
                <Button variant="primary" disabled={isEditMode} onClick={createSurvey}>Create</Button>
            </SpaceBetween>
            <Table
                onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
                selectedItems={selectedItems}
                loading={loading}
                columnDefinitions={[
                    {
                        id: "name",
                        header: "Survey name",
                        cell: (e) => e.surveyName,
                    },
                    {
                        id: "id",
                        header: "Id",
                        cell: (e) => e.surveyId,
                    },
                    {
                        id: "questions",
                        header: "# questions",
                        cell: (e) => e.questions.length,
                    },
                ]}
                items={displayedSurveys}
                loadingText="Loading surveys"
                selectionType="single"
                trackBy="surveyId"
                visibleColumns={["name", "id"]}
                empty={
                    <Box textAlign="center" color="inherit">
                        <b>No surveys</b>
                        <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                            No surveys to display.
                        </Box>
                    </Box>
                }
                header={
                    <Header>
                        Published surveys{" "}
                        <Button iconName="refresh" variant="icon" onClick={refresh}>
                            OK
                        </Button>
                    </Header>
                }
                pagination={
                    <Pagination
                        currentPageIndex={currentPageIndex}
                        pagesCount={Math.ceil(surveys.length / 5)}
                        onChange={(event) => {
                            paginate(event.detail.currentPageIndex);
                        }}
                        ariaLabels={{
                            nextPageLabel: "Next page",
                            previousPageLabel: "Previous page",
                            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                        }}
                    />
                }
            />
            {selectedSurvey && <Survey survey={selectedSurvey} editable={isEditMode} setEditable={(value) => setIsEditMode(value)} configs={configs}
                refresh={refresh} updatedConfigs={updatedConfigs} setUpdatedConfigs={setUpdatedConfigs}/>}
        </>
    );
};

export default SurveysList;
