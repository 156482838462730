import { SpaceBetween } from "@awsui/components-react";
import { Auth } from "@aws-amplify/auth";
import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SurveysList from "../SurveysList/SurveysList";

interface HomeProps {
    authenticated: (value: boolean) => void
}

const Home: FC<HomeProps> = (props) => {
    const nav = useNavigate();

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((data) => {
                sessionStorage.setItem("jwt", data.signInUserSession.idToken.jwtToken);
                props.authenticated(true);
            })
            .catch((err) => {
                nav("/login");
            });
    }, []);

    return (
        <>
            <SpaceBetween size="l">
                <SurveysList/>
            </SpaceBetween>
        </>
    );
};

export default Home;
